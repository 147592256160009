/**
 * https://css-tricks.com/lets-create-a-lightweight-native-event-bus-in-javascript/#aa-an-example
 */

class EventBus<DetailType = any> {
    private eventTarget: EventTarget;
    private listeners: Map<
        string,
        Map<(event: CustomEvent<DetailType>) => void, EventListener>
    >;

    constructor(description = "") {
        this.eventTarget = document.appendChild(
            document.createComment(description)
        );
        this.listeners = new Map();
    }

    on(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        const wrappedListener: EventListener = (evt) =>
            listener(evt as CustomEvent<DetailType>);

        if (!this.listeners.has(type)) {
            this.listeners.set(type, new Map());
        }
        this.listeners.get(type)?.set(listener, wrappedListener);
        this.eventTarget.addEventListener(type, wrappedListener);
    }

    once(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        const wrappedListener: EventListener = (evt) =>
            listener(evt as CustomEvent<DetailType>);
        if (!this.listeners.has(type)) {
            this.listeners.set(type, new Map());
        }
        this.listeners.get(type)?.set(listener, wrappedListener);
        this.eventTarget.addEventListener(type, wrappedListener, {
            once: true
        });
    }

    off(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        const listenersOfType = this.listeners.get(type);
        if (listenersOfType?.has(listener)) {
            const wrappedListener = listenersOfType.get(listener)!;
            this.eventTarget.removeEventListener(type, wrappedListener);
            listenersOfType.delete(listener);
            if (listenersOfType.size === 0) {
                this.listeners.delete(type);
            }
        }
    }

    emit(type: string, detail?: DetailType) {
        return this.eventTarget.dispatchEvent(
            new CustomEvent(type, { detail })
        );
    }
}

export const eventBus = new EventBus("SwitcherEmbeddedEventBus");
