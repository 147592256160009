import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestAccessButtonGroup } from "components/Buttons/RequestAccessButtonGroup";
import {
    EntitlementResponse,
    ProductMetaDataDetailsResponseMetaDataResponse
} from "@switcherstudio/player-api-client";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
export interface PurchaseOptionsProps {
    entitlement: EntitlementResponse | undefined;
    disabled?: boolean;
    title?: string;
    entityId?: string | undefined;
    email: string;
    metadata: ProductMetaDataDetailsResponseMetaDataResponse | undefined;
    setError?: (error: string) => void;
}

export const PurchaseOptions: React.FC<PurchaseOptionsProps> = ({
    entitlement,
    disabled,
    title,
    email,
    metadata,
    setError
}) => {
    const { t } = useTranslation();
    const cx = classNames.bind(styles);

    const [entitledDuration, entitledDurationUnit] = useMemo(() => {
        return [
            entitlement?.prices?.[0]?.details?.entitledDuration,
            entitlement?.prices?.[0]?.details?.entitledDurationUnit
        ];
    }, [entitlement?.prices]);

    const getLastUpdatedText = useCallback(
        (lastUpdated: Date) => {
            const now = new Date();
            const updatedDate = new Date(lastUpdated);
            const diffInMinutes = Math.floor(
                (now.getTime() - updatedDate.getTime()) / (1000 * 60)
            );
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);
            let result;

            switch (true) {
                case diffInMinutes < 60:
                    result = t("time:less-than-hour");
                    break;
                case diffInHours === 1:
                    result = t("time:one-hour");
                    break;
                case diffInHours < 24:
                    result = t("time:hours-ago", { count: diffInHours });
                    break;
                case diffInDays === 1:
                    result = t("time:one-day");
                    break;
                case diffInDays < 365:
                    result = t("time:days-ago", { count: diffInDays });
                    break;
                default:
                    result = t("time:year-plus");
                    break;
            }

            return `${t("metadata:last-updated")} ${result}`;
        },
        [t]
    );

    const metaInfo = useMemo(() => {
        if (!metadata) return null;
        const meta = metadata.items?.find(
            (item) => item.entitlementType === entitlement?.details?.type
        );
        if (!meta) return null;
        const { entitlementType, lastUpdated } = meta;
        const totalVideos = metadata.items?.reduce(
            (total, item) => total + (item.totalVideos || 0),
            0
        );
        const totalCollections = metadata.items?.reduce(
            (total, item) => total + (item.totalCollections || 0),
            0
        );
        const infoArray = [];

        if (
            entitlementType === "Catalog" &&
            typeof totalCollections !== "undefined"
        ) {
            infoArray.push(
                `${totalCollections} ${
                    totalCollections > 1
                        ? t("metadata:collections")
                        : t("metadata:collection")
                }`
            );
        }

        if (
            entitlementType === "Collection" &&
            totalCollections &&
            totalCollections > 1
        ) {
            infoArray.push(
                `${t("metadata:and")} ${totalCollections - 1} ${t(
                    "metadata:other"
                )} ${
                    totalCollections - 1 > 1
                        ? t("metadata:collections")
                        : t("metadata:collection")
                }`
            );
        }

        if (
            entitlementType === "Video" &&
            totalCollections &&
            totalCollections > 1
        ) {
            infoArray.push(
                `${t("metadata:and")} ${totalCollections} ${t(
                    "metadata:other"
                )} ${
                    totalCollections > 1
                        ? t("metadata:collections")
                        : t("metadata:collection")
                }`
            );
        }

        if (
            (entitlementType === "Catalog" ||
                entitlementType === "Collection") &&
            totalVideos &&
            totalVideos > 0
        ) {
            const videoText = `${totalVideos} ${
                totalVideos > 1 ? t("metadata:videos") : t("metadata:video")
            }`;

            if (infoArray.length > 0) {
                infoArray[infoArray.length - 1] += ` ${t(
                    "metadata:with"
                )} ${videoText}`;
            } else {
                infoArray.push(videoText);
            }
        }

        if (
            entitlementType === "Video" &&
            totalVideos &&
            totalVideos > 1 &&
            !totalCollections
        ) {
            infoArray.push(
                `${t("metadata:and")} ${totalVideos - 1} ${t(
                    "metadata:other"
                )} ${
                    totalVideos - 1 > 1
                        ? t("metadata:videos")
                        : t("metadata:video")
                }`
            );
        }

        if (
            entitlementType === "Video" &&
            totalVideos &&
            totalVideos > 1 &&
            totalCollections
        ) {
            infoArray.push(
                `${t("metadata:with")} ${totalVideos - 1} ${
                    totalVideos - 1 > 1
                        ? t("metadata:videos")
                        : t("metadata:video")
                }`
            );
        }

        if (entitledDuration && entitledDuration > 0) {
            infoArray.push(
                t("purchase-options:rental-duration-notice", {
                    durationString: `• ${entitledDuration} ${entitledDurationUnit?.toLocaleLowerCase()}`
                })
            );
        }

        if (entitlementType !== "Video" && lastUpdated) {
            if (infoArray.length > 0) {
                infoArray.push(`• ${getLastUpdatedText(lastUpdated)}`);
            } else {
                infoArray.push(getLastUpdatedText(lastUpdated));
            }
        }

        const metaText = infoArray.join(" ").replace(/\s+•\s*$/, "");

        return <small>{metaText}</small>;
    }, [
        metadata,
        entitledDuration,
        entitlement?.details?.type,
        t,
        entitledDurationUnit,
        getLastUpdatedText
    ]);

    const passDescription = useMemo(() => {
        return entitlement?.product?.details?.description;
    }, [entitlement]);

    return (
        <div className={cx("section-container")}>
            <span className={cx("section-heading")}>{title}</span>
            <span className={cx("meta")}>{metaInfo}</span>
            <span className={cx("pass-description")}>
                <small>{passDescription}</small>
            </span>
            <RequestAccessButtonGroup
                disabled={disabled}
                entitlement={entitlement}
                email={email}
                setError={setError}
            />
        </div>
    );
};
