import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useDispatch } from "react-redux";
import { IFrameType } from "store/VideoSession/types";
import { useGatedContent } from "hooks/useGatedContent";
import { setActiveModal } from "store/Modals/slice";
import { Modals } from "store/Modals/types";
import { EntitlementResponse } from "@switcherstudio/player-api-client";
import { EmailAccessRequest, payments } from "api/payment/client";
import { EmailInput } from "components/inputs/text-input/EmailInput";
import { GenericModal } from "../GenericModal";
import { validEmail } from "helpers/email";
import { exchangeCode } from "store/CreatorCustomers/thunks";
import { AppDispatch } from "store/store";
import useCollectionWithVideos from "hooks/useCollectionWithVideos";

export const EmailAccessModal: React.FC = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading } = useSelector((s: RootState) => s.loading);
    const { iframeType, parentFrame, currentCollectionVideo } = useSelector(
        (s: RootState) => s.videoSession
    );
    const { catalog } = useSelector((s: RootState) => s.catalogState);
    const { collection } = useCollectionWithVideos();
    const [isProcessing, setIsProcessing] = useState(false);
    const {
        catalogEmailEntitlements,
        playerEmailEntitlements,
        playlistBroadcastEmailEntitlements,
        availablePasswordEntitlements,
        availableEmailEntitlements
    } = useGatedContent();

    const getHighestLevelEntitlement = (
        entitlements: EntitlementResponse[]
    ) => {
        return (
            entitlements.find(
                (entitlement) => entitlement.details.type === "Catalog"
            ) ||
            entitlements.find(
                (entitlement) => entitlement.details.type === "Collection"
            ) ||
            entitlements.find(
                (entitlement) => entitlement.details.type === "Video"
            ) ||
            null
        );
    };

    const highestLevelEntitlement = useMemo(() => {
        return getHighestLevelEntitlement(availableEmailEntitlements);
    }, [availableEmailEntitlements]);

    const entityName = useMemo(() => {
        switch (highestLevelEntitlement?.details?.type) {
            case "Catalog":
                return catalog?.details?.title ?? "";

            case "Collection":
                return collection?.details?.name;

            case "Video":
                return currentCollectionVideo?.broadcast?.details?.title;
        }
    }, [catalog, collection, currentCollectionVideo, highestLevelEntitlement]);

    const entityId = highestLevelEntitlement?.details?.entitlementId;
    const entityType = highestLevelEntitlement?.details?.type;
    const redeemEmailAccess = useCallback(
        async (email: string) => {
            try {
                setIsProcessing(true);
                const requestBody: EmailAccessRequest = {
                    Email: email,
                    EntityId: entityId,
                    EntityType: entityType,
                    EntityName: entityName
                };

                const response = await payments.CreateOrValidateEmailAccess(
                    requestBody
                );

                await dispatch(exchangeCode(response.AuthToken ?? ""));

                dispatch(setActiveModal(Modals.None));
            } catch (e) {
                setIsProcessing(false);
                dispatch(setActiveModal(Modals.None));
            }
        },
        [entityId, entityType, entityName, dispatch]
    );

    const description = useMemo(() => {
        if (catalogEmailEntitlements.length > 0) {
            return t("purchase-options:email-cta-catalog");
        }
        if (playerEmailEntitlements.length > 0) {
            return t("purchase-options:email-cta-collection");
        }
        if (playlistBroadcastEmailEntitlements.length > 0) {
            return t("purchase-options:email-cta-video");
        }
        return "";
    }, [
        catalogEmailEntitlements,
        playerEmailEntitlements,
        playlistBroadcastEmailEntitlements,
        t
    ]);

    const footerLink = useMemo(() => {
        if (availablePasswordEntitlements.length > 0) {
            return t("purchase-options:password-unlock");
        } else {
            return "";
        }
    }, [availablePasswordEntitlements, t]);

    const openPasswordAccessModal = useCallback(() => {
        dispatch(setActiveModal(Modals.PasswordAccess));
    }, [dispatch]);

    return (
        <GenericModal
            title={t("modals:enter-email")}
            successButton={t("buttons:submit")}
            onSuccess={() => redeemEmailAccess(email ?? "")}
            onClose={() => {
                iframeType === IFrameType.AuxModal &&
                    parentFrame?.closePurchaseModal();
            }}
            successButtonDisabled={!validEmail(email ?? "") || isLoading}
            preventCloseOnSuccess={true}
            preventDismiss={isLoading}
            footerLinkText={footerLink}
            footerLinkAction={openPasswordAccessModal}
            isProcessing={isProcessing}
            processingHeader={t("modals:processing")}
            processingDescriptionLines={[t("modals:processing-description")]}
        >
            <p>{description}</p>
            <EmailInput
                label={t("inputs:email-address")}
                value={email ?? ""}
                onChange={(event) => setEmail(event.target.value)}
                isLoading={isLoading}
            />
        </GenericModal>
    );
};
